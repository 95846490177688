<template>
	<TheHeader>
		<template v-slot:title>
			14-Day Free Trial, No Credit Card Needed. <span class="line relative inline-block text-theme">Simple.</span>
		</template>
		<template v-slot:description>
			Try now and pay later. Optiomat doesn't charge you any fees during the initial 14-day free trial. Then you would choose one of the standard packages, exactly according to your needs.
		</template>
	</TheHeader>

	<section class="page-container max-w-screen-lg mt-16">
		<h3 data-aos="fade-up" class="text-center text-2xl font-extrabold mb-3">
			{{ essentials.title }}
		</h3>

		<div data-aos="fade-up" class="text-center mb-12">
			<label for="toggle" :class="{'text-theme': isYearlyEssentials}" class="font-bold cursor-pointer">
				Billed Annually
			</label>

			<div class="relative inline-block w-14 mx-6 align-middle select-none transition duration-200 ease-in">
				<input v-model="isYearlyEssentials" type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-8 h-8 rounded-full bg-white border-4 border-secondary appearance-none cursor-pointer"/>
				<label for="toggle" class="toggle-label block overflow-hidden h-8 rounded-full bg-secondary cursor-pointer"></label>
			</div>

			<label for="toggle" :class="{'text-theme': !isYearlyEssentials}" class="font-bold cursor-pointer">
				Billed Monthly
			</label>
		</div>

		<div class="md:grid md:grid-cols-3 lg:gap-12 md:gap-6 mb-20">
			<div data-aos="fade-up" v-for="(plan, i) in essentials.plans" class="bg-secondary rounded-xl p-5 md:mb-0 mb-10 max-w-lg mx-auto">
				<h4 class="font-extrabold text-xl mb-5">
					{{ plan.name }}
				</h4>
				<div class="mb-12">
					<span class="text-4xl font-black block">
						{{ isYearlyEssentials ? plan.price.yearly : plan.price.monthly }}
					</span>

					<span class="font-bold text-sm text-theme" :class="{'opacity-0': !(i !== 2 && isYearlyEssentials)}">
						{{ i !== 2 && isYearlyEssentials ? 'Two Months Free' : '.'}}
					</span>
				</div>
				<div v-for="feature in plan.features" class="flex items-start mb-2">
					<div>
						<svg class="stroke-current text-theme transform scale-75 origin-left" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
							<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
							<polyline points="22 4 12 14.01 9 11.01"></polyline>
						</svg>
					</div>
					<p class="font-bold ml-1.5">
						{{ feature }}
					</p>
				</div>
				<a v-if="i !== 2" :href="registerButton" target="_blank" class="hover:bg-secondary hover:text-primary transition-all border-3 border-primary bg-theme-dark p-2 block rounded-lg text-white text-center font-bold mt-8">
					Start Trial as Founder
				</a>
				<a v-if="i === 2" @click="openContactFormPopup" class="cursor-pointer hover:bg-theme-dark hover:text-white transition-all border-3 border-primary p-2 block rounded-lg text-center font-bold mt-8">
					Contact Us
				</a>
			</div>
		</div>

		<div class="page-container">
			<h4 data-aos="fade-up" class="text-2xl font-extrabold mt-12 mb-12">
				All {{ essentials.title }} Include:
			</h4>

			<DescriptionCheck data-aos="fade-up" class="mb-8" v-for="item in essentials.packages">
				<span v-html="item"></span>
			</DescriptionCheck>
		</div>
	</section>

	<figure class="bg-wave-dark bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5 mt-16"></figure>

	<div class="bg-primary pt-16 pb-24 -mb-32">
		<section class="page-container max-w-screen-lg">
			<h3 data-aos="fade-up" class="text-center text-2xl text-white font-extrabold mb-3">
				{{ complete.title }}
			</h3>

			<div data-aos="fade-up" class="text-center mb-12">
				<label for="toggle-2" :class="{'text-theme': isYearlyComplete}" class="font-bold text-white cursor-pointer">
					Billed Annually
				</label>

				<div class="relative inline-block w-14 mx-6 align-middle select-none transition duration-200 ease-in">
					<input v-model="isYearlyComplete" type="checkbox" name="toggle-2" id="toggle-2" class="toggle-checkbox absolute block w-8 h-8 rounded-full bg-white border-4 border-primary-light appearance-none cursor-pointer"/>
					<label for="toggle-2" class="toggle-label block overflow-hidden h-8 rounded-full bg-primary-light cursor-pointer"></label>
				</div>

				<label for="toggle-2" :class="{'text-theme': !isYearlyComplete}" class="font-bold text-white cursor-pointer">
					Billed Monthly
				</label>
			</div>

			<div class="md:grid md:grid-cols-3 lg:gap-12 md:gap-6 mb-20">
				<div data-aos="fade-up" v-for="(plan, i) in complete.plans" class="bg-primary-light rounded-xl p-5 md:mb-0 mb-10 max-w-lg mx-auto">
					<h4 class="font-extrabold text-xl mb-5 text-dark">
						{{ plan.name }}
					</h4>

					<div class="mb-12">
						<span class="text-4xl font-black block text-white">
							{{ isYearlyComplete ? plan.price.yearly : plan.price.monthly }}
						</span>
						<span class="font-bold text-sm text-theme" :class="{'opacity-0': !(i !== 2 && isYearlyComplete)}">
							{{ i !== 2 && isYearlyComplete ? 'Two Months Free' : '.'}}
						</span>
					</div>

					<div v-for="feature in plan.features" class="flex items-start mb-2">
						<svg class="mr-1.5 stroke-current text-theme transform scale-75 origin-left" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
							<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
							<polyline points="22 4 12 14.01 9 11.01"></polyline>
						</svg>
						<p class="font-bold text-dark">
							{{ feature }}
						</p>
					</div>
					<a v-if="i !== 2" :href="registerButton" target="_blank" class="hover:border-theme hover:text-theme hover:bg-primary-light transition-all border-3 border-theme bg-theme p-2 block rounded-lg text-white text-center font-bold mt-8">
						Start Trial as Founder
					</a>
					<span v-if="i === 2" @click="openContactFormPopup" class="cursor-pointer hover:bg-theme hover:text-white transition-all border-3 border-theme p-2 block rounded-lg text-center text-theme font-bold mt-8">
						Contact Us
					</span>
				</div>
			</div>

			<div class="page-container">
				<h4 data-aos="fade-up" class="text-2xl text-white font-extrabold mt-12 mb-12">
					All {{ complete.title }} Include:
				</h4>

				<DescriptionCheck data-aos="fade-up" class="mb-8" v-for="item in complete.packages">
					<span v-html="item" class="text-dark"></span>
				</DescriptionCheck>
			</div>
		</section>
	</div>

	<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5 mt-20"></figure>

	<section class="page-container max-w-screen-lg mt-16">
		<h3 data-aos="fade-up" class="text-center text-2xl font-extrabold mb-3">
			{{ consulting.title }}
		</h3>

		<div data-aos="fade-up" class="text-center mb-12">
			<label class="font-bold">
				{{ consulting.description }}
			</label>
		</div>

		<div class="md:grid md:grid-cols-3 lg:gap-12 md:gap-6 mb-20">
			<div data-aos="fade-up" v-for="(plan, i) in consulting.plans" class="bg-secondary rounded-xl p-5 md:mb-0 mb-10 max-w-lg mx-auto">
				<h4 class="font-extrabold text-xl mb-5">
					{{ plan.name }}
				</h4>
				<div class="mb-12">
					<span class="text-4xl font-black block" :class="{'lg:pb-10': i < 2}">
						{{ plan.price }}
					</span>
				</div>
				<div v-for="feature in plan.features" class="flex items-start mb-2">
					<div>
						<svg class="stroke-current text-theme transform scale-75 origin-left" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
							<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
							<polyline points="22 4 12 14.01 9 11.01"></polyline>
						</svg>
					</div>
					<p class="font-bold ml-1.5">
						{{ feature }}
					</p>
				</div>
			</div>
		</div>

		<div class="page-container">
			<h4 data-aos="fade-up" class="text-2xl font-extrabold mt-12 mb-12">
				All {{ consulting.title }} Include:
			</h4>

			<DescriptionCheck data-aos="fade-up" class="mb-8" v-for="item in consulting.packages">
				<span v-html="item"></span>
			</DescriptionCheck>
		</div>
	</section>
</template>

<script>
	import DescriptionCheck from "../components/DescriptionCheck";
	import TheHeader from "../components/TheHeader";
	import {mapGetters} from "vuex";

	export default {
		name: 'Pricing',
		components: {
			DescriptionCheck,
			TheHeader,
		},
		computed: {
			...mapGetters([
				'registerButton',
			])
		},
		data() {
			return {
				isYearlyEssentials: true,
				isYearlyComplete: true,
				consulting: {
					title: 'Consulting Packages',
					description: 'One-time billing',
					plans: [
						{
							name: 'Onboarding Assistance',
							price: '3000€',
							features: [
								'Up to 30 hours of consultations',
							]
						},
						{
							name: 'Tailored Assistance',
							price: '5000€',
							features: [
								'Up to 60 hours of consultations',
							]
						},
						{
							name: 'Unlimited Assistance',
							price: 'Preferential hourly fee',
							features: [
								'Above 60 hours of consultations',
							]
						},
					],
					packages: [
						'The fee for stated number of consultation hours, including advisory, meetings, review of specific positions and follow-up communications'
					],
				},
				essentials: {
					title: 'Essential Packages',
					plans: [
						{
							name: 'Startup Essentials',
							price: {
								monthly: '59€/Mo.',
								yearly: '49€/Mo.',
							},
							features: [
								'Up to 20 employee share option plans',
							],
						},
						{
							name: 'Scaleup Essentials',
							price: {
								monthly: '119€/Mo.',
								yearly: '99€/Mo.',
							},
							features: [
								'Up to 50 employee share option plans',
							],
						},
						{
							name: 'Unlimited Essentials',
							price: {
								monthly: 'Custom',
								yearly: 'Custom',
							},
							features: [
								'Unlimited number of employee share option plans',
							],
						},
					],
					packages: [
						'OptioPool<span class="tm-xs text-theme">TM</span> Workflow for establishing, updating and cancelling option pools',
						'OptioPlan<span class="tm-xs text-theme">TM</span> Workflow for inviting employees and creating option plans',
						'OptioSign<span class="tm-xs text-theme">TM</span> Workflow for making documents legally binding',
						'OptioManager<span class="tm-xs text-theme">TM</span> Workflow for evaluating milestones and early settlement of option plan',
						'OptioView<span class="tm-xs text-theme">TM</span> Workflow for searching, grouping and viewing information on option pools, employee share option plans, employees, activities and transactions',
					],
				},
				complete: {
					title: 'Complete Packages',
					plans: [
						{
							name: 'Startup Complete',
							price: {
								monthly: '279€/Mo.',
								yearly: '229€/Mo.',
							},
							features: [
								'Up to 20 employee share option plans',
							],
						},
						{
							name: 'Scaleup Complete',
							price: {
								monthly: '479€/Mo.',
								yearly: '399€/Mo.',
							},
							features: [
								'Up to 50 employee share option plans',
							],
						},
						{
							name: 'Unlimited Complete',
							price: {
								monthly: 'Custom',
								yearly: 'Custom',
							},
							features: [
								'Unlimited number of employee share option plans',
							],
						},
					],
					packages: [
						'Everything in Essentials',
						'OptioPlus<span class="tm-xs text-theme">TM</span> Workflow for appointing a trustee, exchanging shares for share certificates and automating option settlement processes',
						'Out-of-the-box Stichting Administratiekantoor trust office Arrangement for holding option pool shares in trust and issuing share certificates as a separate class for employee participation',
					],
				},
			}
		},
		methods: {
			openContactFormPopup() {
				if (window.innerWidth < 640) {
					let el = document.getElementById('contact-us')

					this.$store.commit('TOGGLE_CONTACT_FORM', true)

					window.scrollTo({
						top: el.getBoundingClientRect().top + window.scrollY,
						behavior: 'smooth',
					})

				} else {
					this.$store.commit('TOGGLE_CONTACT_POPUP', true)
				}
			}
		}
	}
</script>

<style>
	.toggle-checkbox:checked {
		@apply: right-0 border-green-400;
		right: 0;
		border-color: #5151E7;
	}

	.toggle-checkbox:checked + .toggle-label {
		@apply: bg-theme;
		background-color: #5151E7;
	}
</style>
